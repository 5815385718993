/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Au77 = ({ className }) => {
  return (
    <svg
      className={`au-77 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_1009_2459)">
        <path
          className="path"
          d="M16 8C16 12.4183 12.4183 16 8 16C3.58175 16 0 12.4183 0 8C0 8.00187 8 0.00090625 8 0C12.4183 0 16 3.58175 16 8Z"
          fill="#0052B4"
        />
        <path
          className="path"
          d="M8.00061 -6.10352e-05C7.99994 -6.10352e-05 7.99921 -1.27929e-05 7.99854 -1.27929e-05L8.00061 -6.10352e-05Z"
          fill="#F0F0F0"
        />
        <path
          className="path"
          d="M7.97852 8.00026H7.99996C7.99996 7.993 7.99996 7.98605 7.99996 7.97882C7.99283 7.98599 7.98569 7.99313 7.97852 8.00026Z"
          fill="#F0F0F0"
        />
        <path
          className="path"
          d="M7.99975 4.17445C7.99975 2.7668 7.99975 1.84439 7.99975 0.000549316H7.99841C3.58075 0.00126807 -0.000244141 3.58267 -0.000244141 8.00052H4.17366V5.65014L6.52403 8.00052H7.97838C7.98553 7.99339 7.99266 7.98627 7.99978 7.97911C7.99978 7.44014 7.99978 6.95924 7.99978 6.52486L5.64938 4.17445H7.99975Z"
          fill="#F0F0F0"
        />
        <path
          className="path"
          d="M4.04758 1.04382C2.79636 1.75629 1.75617 2.79648 1.0437 4.0477V8.00036H3.13067V3.13082V3.13079H8.00023C8.00023 2.47257 8.00023 1.84551 8.00023 1.04382H4.04758Z"
          fill="#D80027"
        />
        <path
          className="path"
          d="M8.00037 7.01664L5.1581 4.17439H4.17432C4.17432 4.17436 4.17432 4.17439 4.17432 4.17439L8.00034 8.00043H8.00037C8.00037 8.00043 8.00037 7.32214 8.00037 7.01664Z"
          fill="#D80027"
        />
        <path
          className="path"
          d="M4.8251 9.39093L5.26417 10.309L6.25563 10.0798L5.81164 10.9955L6.60895 11.6278L5.61623 11.8516L5.61901 12.8692L4.8251 12.2325L4.03123 12.8692L4.03401 11.8516L3.04126 11.6278L3.8386 10.9955L3.39454 10.0798L4.38607 10.309L4.8251 9.39093Z"
          fill="#F0F0F0"
        />
        <path
          className="path"
          d="M11.9781 11.1302L12.1976 11.5892L12.6934 11.4746L12.4713 11.9325L12.87 12.2486L12.3736 12.3605L12.375 12.8693L11.9781 12.551L11.5811 12.8693L11.5825 12.3605L11.0862 12.2486L11.4848 11.9325L11.2628 11.4746L11.7586 11.5892L11.9781 11.1302Z"
          fill="#F0F0F0"
        />
        <path
          className="path"
          d="M9.93564 6.26093L10.1551 6.71999L10.6509 6.60536L10.4289 7.06321L10.8275 7.37936L10.3312 7.49124L10.3325 8.00005L9.93564 7.68171L9.5387 8.00005L9.54004 7.49124L9.0437 7.37936L9.44236 7.06321L9.22036 6.60536L9.71611 6.71999L9.93564 6.26093Z"
          fill="#F0F0F0"
        />
        <path
          className="path"
          d="M11.9781 3.47839L12.1976 3.93746L12.6934 3.82286L12.4714 4.28071L12.87 4.59683L12.3736 4.70874L12.375 5.21755L11.9781 4.89921L11.5811 5.21755L11.5825 4.70874L11.0862 4.59683L11.4848 4.28071L11.2628 3.82286L11.7586 3.93746L11.9781 3.47839Z"
          fill="#F0F0F0"
        />
        <path
          className="path"
          d="M13.7613 5.56512L13.9808 6.02419L14.4766 5.90956L14.2546 6.36741L14.6532 6.68356L14.1569 6.79547L14.1582 7.30425L13.7613 6.98594L13.3644 7.30425L13.3657 6.79547L12.8694 6.68356L13.268 6.36741L13.046 5.90956L13.5418 6.02419L13.7613 5.56512Z"
          fill="#F0F0F0"
        />
        <path
          className="path"
          d="M12.4861 8.00018L12.6588 8.53162H13.2176L12.7655 8.86009L12.9382 9.3915L12.4861 9.06306L12.034 9.3915L12.2067 8.86009L11.7546 8.53162H12.3134L12.4861 8.00018Z"
          fill="#F0F0F0"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_1009_2459">
          <rect className="rect" fill="white" height="16" width="16" />
        </clipPath>
      </defs>
    </svg>
  );
};
