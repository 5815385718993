/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import { ArrowDown1 } from "../../icons/ArrowDown1";
import { ArrowUp } from "../../icons/ArrowUp";
import "./style.css";

export const TableHeader = ({ helpIcon, arrow, stateProp, className, text = "Company" }) => {
  const [state, dispatch] = useReducer(reducer, {
    helpIcon: helpIcon || false,
    arrow: arrow || "false",
    state: stateProp || "default",
  });

  return (
    <div
      className={`table-header ${className}`}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
    >
      <div className={`text-4 ${state.state}`}>{text}</div>
      {state.helpIcon && <div className={`help-icon ${state.arrow} state-${state.state}`} />}

      {state.arrow === "down" && (
        <ArrowDown1
          className="instance-node-2"
          color={state.state === "disabled" ? "#98A2B3" : state.state === "hover" ? "#344054" : "#475467"}
        />
      )}

      {state.arrow === "up" && (
        <ArrowUp
          className="instance-node-2"
          color={state.state === "disabled" ? "#98A2B3" : state.state === "hover" ? "#344054" : "#475467"}
        />
      )}
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        state: "hover",
      };

    case "mouse_leave":
      return {
        ...state,
        state: "default",
      };
  }

  return state;
}

TableHeader.propTypes = {
  helpIcon: PropTypes.bool,
  arrow: PropTypes.oneOf(["false", "up", "down"]),
  stateProp: PropTypes.oneOf(["disabled", "hover", "default"]),
  text: PropTypes.string,
};
