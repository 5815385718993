import React, { useState } from "react";
import { useAuth } from "../../authContext";
import { useNavigate } from "react-router-dom";
import { Logomark } from "../../icons/Logomark";
import "./style.css";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // New state variable for error messages
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async () => {
    console.log("Attempting to log in...");
    if (!email || !password) {
      setErrorMessage("Please enter both email and password.");
      return;
    }
  
    try {
      await login(email, password);
      console.log("Login successful!");
      navigate("/desktop");
    } catch (error) {
      console.error("Failed to log in: ", error);
  
      // Here you can handle different error codes if your authentication service provides them
      if (error.code === "auth/wrong-password") {
        setErrorMessage("Incorrect password. Please try again.");
      } else if (error.code === "auth/user-not-found") {
        setErrorMessage("No account associated with this email.");
      } else if (error.code === "auth/network-request-failed") {
        setErrorMessage("Network error. Please check your connection.");
      } else {
        // For other errors, you might want to generalize the error message for security
        setErrorMessage("Incorrect password. Please try again.");
      }
    }
  };
  
  
  return (
    <div className="login">
      <div className="content-wrapper">
        <div className="content-5">
          <header className="header">
            <div className="logo">
              <img className="vector" alt="Vector" src="/img/vector.svg" />
              <Logomark className="logomark-instance" />
            </div>
            <div className="text-and-supporting">
              <p className="p">Log in</p>
              <p className="supporting-text-2">Welcome back! Please enter your details.</p>
            </div>
          </header>
          <div className="content-6">
            {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Display error message when it exists */}
            <div className="form">
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && handleLogin()} // Add this line
              className="input-field-instance design-component-instance-node"
            />
            <input
              type="password"
              placeholder="••••••••"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && handleLogin()} // Add this line
              className="input-field-2"
            />

            </div>
            <div className="actions">
              <button onClick={handleLogin} className="buttons-button-instance">
                Continue →
              </button>
            </div>
          </div>
          <div className="footer-links">
            <div className="row">
              <div className="text-14">Don’t have an account? Send us a message.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
