import React, { useState } from "react";
import { auth, db } from "../../firebase";
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { collection, doc, setDoc } from 'firebase/firestore';
import { Logomark } from "../../icons/Logomark";

export const AdminRegister = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [affiliateCode, setAffiliateCode] = useState("");
  const [ethereumAddress, setEthereumAddress] = useState("");
  const [message, setMessage] = useState({ text: "", type: "" }); // New state for message and type

  // Function to convert Firebase error codes to user-friendly messages
  const getErrorMessage = (errorCode) => {
    switch (errorCode) {
      case 'auth/email-already-in-use':
        return 'This email is already in use.';
      case 'auth/invalid-email':
        return 'The email address is not valid.';
      // Add more cases as needed for different Firebase errors
      default:
        return 'The email is already in use.';
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Store additional details in Firestore
      const userDoc = doc(collection(db, "users"), user.uid);
      await setDoc(userDoc, {
        firstName,
        affiliateCode,
        ethereumAddress,
      });

      setMessage({ text: "User registered successfully", type: "success" });
      console.log("User registered successfully");
    } catch (error) {
      console.error("Error registering user:", error);
      // Use the custom function to set a user-friendly error message
      const userFriendlyMessage = getErrorMessage(error.code);
      setMessage({ text: userFriendlyMessage, type: "error" });
    }
  };

  return (
    <div className="login">
      <div className="content-wrapper">
        <div className="content-5">
          <header className="header">
            <div className="logo">
              <img className="vector" alt="Vector" src="/img/vector.svg" />
              <Logomark className="logomark-instance" />
            </div>
            <div className="text-and-supporting">
              <p className="p">Add Influencer</p>
              <p className="supporting-text-2">Fill in the details to register a profile.</p>
            </div>
          </header>
          <div className="content-6">
            {/* Display the message if it exists */}
            {message.text && (
              <p className={`message ${message.type === 'error' ? 'error-message' : 'success-message'}`}>
                {message.text}
              </p>
            )}
            <div className="form">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="input-field-instance design-component-instance-node"
              />
              <input
                type="password"
                placeholder="••••••••"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="input-field-2"
              />
              <input
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="input-field-2"
              />
              <input
                placeholder="Affiliate Code"
                value={affiliateCode}
                onChange={(e) => setAffiliateCode(e.target.value)}
                className="input-field-2"
              />
              <input
                placeholder="Ethereum Address"
                value={ethereumAddress}
                onChange={(e) => setEthereumAddress(e.target.value)}
                className="input-field-2"
              />
            </div>
            <div className="actions">
              <button onClick={handleRegister} className="buttons-button-instance">
                Register User
              </button>
            </div>
          </div>
          <div className="footer-links">
            <div className="row">
              <div className="text-14">If problems, please contact admin.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

