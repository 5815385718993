/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Logomark = ({ className }) => {
  return (
    <svg
      className={`logomark ${className}`}
      fill="none"
      height="18"
      viewBox="0 0 25 18"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M9.13893 8.1389V0H16.5639L24.56 7.99611V16.9917H17.9918L9.13893 8.1389Z"
        fill="#121315"
      />
      <path className="path" d="M9.13841 8.13889H0V17.2773H9.13841V8.13889Z" fill="#121315" />
    </svg>
  );
};
