import React, { useContext } from "react";
import { createBrowserRouter, RouterProvider, useNavigate } from "react-router-dom";
import { Desktop } from "./screens/Desktop";
import { AdminDesktop } from "./screens/AdminDesktop";
import { NoData } from "./screens/NoData";
import { Login } from "./screens/Login";
import { AdminRegister } from "./screens/AdminRegister/AdminRegister";
import AuthContext from "./authContext";

const ProtectedDesktop = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  if (currentUser) {
    return <Desktop />;
  } else {
    navigate("/login");
    return null;
  }
};

const router = createBrowserRouter([
  {
    path: "/*",
    element: <Login />,
  },
  {
    path: "/desktop",
    element: <ProtectedDesktop />,
  },
  {
    path: "/no-data",
    element: <NoData />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/admin-desktop",
    element: <AdminDesktop />,
  },
  {
    path: "/admin-register",
    element: <AdminRegister />,
  },
]);

export const App = () => {
  return <RouterProvider router={router} />;
};