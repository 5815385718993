/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { ArrowRight27 } from "../../icons/ArrowRight27";
import { ArrowUp15 } from "../../icons/ArrowUp15";
import { Au77 } from "../../icons/Au77";
import { Plus3 } from "../../icons/Plus3";
import { XClose24 } from "../../icons/XClose24";
import { Dot } from "../Dot";
import "./style.css";

export const Badge = ({ size, type, icon, color, className, textClassName, text = "Label" }) => {
  return (
    <div className={`badge icon-${icon} ${type} ${color} size-0-${size} ${className}`}>
      {icon === "false" && <div className={`text ${textClassName}`}>{text}</div>}

      {icon === "dot" && (
        <Dot
          className="dot-instance"
          dotClassName={`${
            color === "brand" && ["badge-color", "badge-modern", "pill-color"].includes(type) && "class"
          } ${color === "brand" && type === "pill-outline" && "class-2"} ${
            color === "warning" && ["badge-color", "badge-modern", "pill-color"].includes(type) && "class-3"
          } ${color === "warning" && type === "pill-outline" && "class-4"} ${
            type === "pill-outline" && color === "success" && "class-5"
          } ${["blue-gray", "gray-blue"].includes(color) && "class-6"} ${color === "blue-light" && "class-7"} ${
            color === "indigo" && "class-8"
          } ${color === "purple" && "class-9"} ${color === "blue" && "class-10"} ${color === "pink" && "class-11"} ${
            color === "orange" && "class-12"
          } ${color === "error" && ["badge-color", "badge-modern", "pill-color"].includes(type) && "class-13"} ${
            type === "pill-outline" && color === "error" && "class-14"
          } ${color === "gray" && ["badge-color", "badge-modern", "pill-color"].includes(type) && "class-15"} ${
            color === "gray" && type === "pill-outline" && "class-16"
          }`}
          outline={false}
          size="sm"
        />
      )}

      {["avatar", "x-close"].includes(icon) && (
        <>
          <div className="text-2">
            {icon === "x-close" && <>{text}</>}

            {icon === "avatar" && <div className="contrast-border" />}
          </div>
          <div className="badge-close-x">
            {icon === "x-close" && (
              <XClose24
                className="instance-node"
                color={
                  color === "brand"
                    ? "#B692F6"
                    : color === "warning"
                    ? "#FDB022"
                    : color === "success"
                    ? "#47CD89"
                    : color === "blue-light"
                    ? "#36BFFA"
                    : color === "indigo"
                    ? "#8098F9"
                    : color === "purple"
                    ? "#9B8AFB"
                    : color === "blue"
                    ? "#53B1FD"
                    : color === "pink"
                    ? "#F670C7"
                    : color === "orange"
                    ? "#F38744"
                    : color === "error"
                    ? "#F97066"
                    : color === "gray"
                    ? "#98A2B3"
                    : "#717BBC"
                }
              />
            )}

            {icon === "avatar" && <>{text}</>}
          </div>
        </>
      )}

      {icon === "icon-leading" && (
        <ArrowUp15
          className="instance-node"
          color={
            type === "pill-color" && color === "brand"
              ? "#9E77ED"
              : color === "brand" && ["badge-color", "pill-outline"].includes(type)
              ? "#7F56D9"
              : type === "pill-color" && color === "warning"
              ? "#F79009"
              : color === "warning" && ["badge-color", "pill-outline"].includes(type)
              ? "#DC6803"
              : type === "pill-color" && color === "success"
              ? "#17B26A"
              : color === "success" && ["badge-color", "pill-outline"].includes(type)
              ? "#079455"
              : ["blue-gray", "gray-blue"].includes(color)
              ? "#4E5BA6"
              : color === "blue-light"
              ? "#0BA5EC"
              : color === "indigo"
              ? "#6172F3"
              : color === "purple"
              ? "#7A5AF8"
              : color === "blue"
              ? "#2E90FA"
              : color === "pink"
              ? "#EE46BC"
              : color === "orange"
              ? "#EF6820"
              : type === "pill-color" && color === "error"
              ? "#F04438"
              : color === "error" && ["badge-color", "pill-outline"].includes(type)
              ? "#D92D20"
              : type === "pill-color" && color === "gray"
              ? "#667085"
              : type === "badge-modern" ||
                (color === "gray" && type === "badge-color") ||
                (color === "gray" && type === "pill-outline")
              ? "#475467"
              : undefined
          }
        />
      )}

      {["dot", "icon-leading", "icon-trailing"].includes(icon) && <div className="text-wrapper">{text}</div>}

      {icon === "only" && (
        <Plus3
          className="instance-node"
          color={
            type === "pill-color" && color === "brand"
              ? "#9E77ED"
              : color === "brand" && ["badge-color", "pill-outline"].includes(type)
              ? "#7F56D9"
              : type === "pill-color" && color === "warning"
              ? "#F79009"
              : color === "warning" && ["badge-color", "pill-outline"].includes(type)
              ? "#DC6803"
              : type === "pill-color" && color === "success"
              ? "#17B26A"
              : color === "success" && ["badge-color", "pill-outline"].includes(type)
              ? "#079455"
              : ["blue-gray", "gray-blue"].includes(color)
              ? "#4E5BA6"
              : color === "blue-light"
              ? "#0BA5EC"
              : color === "indigo"
              ? "#6172F3"
              : color === "purple"
              ? "#7A5AF8"
              : color === "blue"
              ? "#2E90FA"
              : color === "pink"
              ? "#EE46BC"
              : color === "orange"
              ? "#EF6820"
              : type === "pill-color" && color === "error"
              ? "#F04438"
              : color === "error" && ["badge-color", "pill-outline"].includes(type)
              ? "#D92D20"
              : type === "pill-color" && color === "gray"
              ? "#667085"
              : type === "badge-modern" ||
                (color === "gray" && type === "badge-color") ||
                (color === "gray" && type === "pill-outline")
              ? "#475467"
              : undefined
          }
        />
      )}

      {icon === "country" && (
        <>
          <Au77 className="AU" />
          <div className="text-3">{text}</div>
        </>
      )}

      {icon === "icon-trailing" && (
        <ArrowRight27
          className="instance-node"
          color={
            type === "pill-color" && color === "brand"
              ? "#9E77ED"
              : color === "brand" && ["badge-color", "pill-outline"].includes(type)
              ? "#7F56D9"
              : type === "pill-color" && color === "warning"
              ? "#F79009"
              : color === "warning" && ["badge-color", "pill-outline"].includes(type)
              ? "#DC6803"
              : type === "pill-color" && color === "success"
              ? "#17B26A"
              : color === "success" && ["badge-color", "pill-outline"].includes(type)
              ? "#079455"
              : ["blue-gray", "gray-blue"].includes(color)
              ? "#4E5BA6"
              : color === "blue-light"
              ? "#0BA5EC"
              : color === "indigo"
              ? "#6172F3"
              : color === "purple"
              ? "#7A5AF8"
              : color === "blue"
              ? "#2E90FA"
              : color === "pink"
              ? "#EE46BC"
              : color === "orange"
              ? "#EF6820"
              : type === "pill-color" && color === "error"
              ? "#F04438"
              : color === "error" && ["badge-color", "pill-outline"].includes(type)
              ? "#D92D20"
              : type === "pill-color" && color === "gray"
              ? "#667085"
              : "#475467"
          }
        />
      )}
    </div>
  );
};

Badge.propTypes = {
  size: PropTypes.oneOf(["md", "lg", "sm"]),
  type: PropTypes.oneOf(["pill-outline", "badge-modern", "badge-color", "pill-color"]),
  icon: PropTypes.oneOf(["icon-leading", "only", "country", "avatar", "false", "dot", "icon-trailing", "x-close"]),
  color: PropTypes.oneOf([
    "warning",
    "blue-light",
    "blue-gray",
    "gray-blue",
    "gray",
    "success",
    "blue",
    "orange",
    "brand",
    "pink",
    "error",
    "purple",
    "indigo",
  ]),
  text: PropTypes.string,
};
