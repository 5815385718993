import React, { useState, useEffect } from "react";
import { Badge } from "../../components/Badge";
import { Dot } from "../../components/Dot";
import { TableHeader } from "../../components/TableHeader";
import { ArrowLeft3 } from "../../icons/ArrowLeft3";
import { BarChart01 } from "../../icons/BarChart01";
import { Logomark } from "../../icons/Logomark";
import { auth, db } from "../../firebase";
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

import "./style.css";

export const AdminDesktop = () => {
  // State Initialization
  const [transactions, setTransactions] = useState([]);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedDateRange, setSelectedDateRange] = useState("All time");
  const [totalAffiliateFee, setTotalAffiliateFee] = useState(0.0);
  const transactionsPerPage = 10; // Display 10 transactions per page
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);


  // Fetching Data
  const fetchTransactions = (dateRange = "All time", address = '0x0') => {
    setIsRefreshing(true);

    const endpoint = `https://g9icolj8b7.execute-api.us-east-2.amazonaws.com/production/v1/swap/affiliate/dashboard?address=${address}`;
    
    // Log the endpoint to the console
    console.log("Endpoint:", endpoint);
    
    fetch(endpoint)
      .then(response => response.json())
      .then(data => {
        let latestSwaps = data.latestSwaps || [];
        const currentDate = new Date();

        switch(dateRange) {
          case "Today":
            latestSwaps = latestSwaps.filter(transaction => {
              const transactionDate = new Date(transaction.date);
              return transactionDate.toDateString() === currentDate.toDateString();
            });
            break;
          case "7 days":
            const oneWeekAgo = new Date(currentDate.getTime() - (7 * 24 * 60 * 60 * 1000));
            latestSwaps = latestSwaps.filter(transaction => {
              const transactionDate = new Date(transaction.date);
              return transactionDate > oneWeekAgo;
            });
            break;
          case "30 days":
            const oneMonthAgo = new Date(currentDate.getTime() - (30 * 24 * 60 * 60 * 1000));
            latestSwaps = latestSwaps.filter(transaction => {
              const transactionDate = new Date(transaction.date);
              return transactionDate > oneMonthAgo;
            });
            break;
        }
        let totalFee = latestSwaps.reduce((acc, transaction) => {
          const rewardValue = transaction.rewards && transaction.rewards.length > 0 
              ? (parseFloat(transaction.rewards[0].amountHumanReadable) * parseFloat(transaction.rewards[0].unitUsdPrice)) / 2 
              : 0;
          return acc + rewardValue;
        }, 0);
        
        setTotalAffiliateFee(totalFee);
        setTransactions(latestSwaps);
        setIsRefreshing(false);
      })
      .catch(error => {
        console.error("Error fetching transactions:", error);
        setIsRefreshing(false);
      });
  };

  useEffect(() => {
    const closeDropdown = (e) => {
      if (!document.querySelector(".dropdown").contains(e.target)) {
        setIsDropdownVisible(false);
      }
    };
  
    document.addEventListener("click", closeDropdown);
  
    return () => {
      document.removeEventListener("click", closeDropdown);
    };
  }, []);  

  const handleDateRangeChange = (dateRange) => {
    setSelectedDateRange(dateRange);
    fetchTransactions(dateRange, userData && userData.ethereumAddress);
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      console.log("Logged out successfully");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const generateChartData = () => {
    const days = 30; // for the last 30 days
    const data = [];
    const labels = [];
  
    for (let i = days - 1; i >= 0; i--) {
      const d = new Date();
      d.setDate(d.getDate() - i);
      labels.push(d.toLocaleDateString());
      const transactionsOnDate = transactions.filter(transaction => {
        const transactionDate = new Date(transaction.date);
        return transactionDate.toDateString() === d.toDateString();
      });
      data.push(transactionsOnDate.length);
    }
  
    return {
      labels,
      datasets: [{
        data,
        fill: false,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
      }],
    };
  };

  const SkeletonLoader = ({ width, height }) => (
    <div className="skeleton" style={{ width, height }}></div>
  );

  function formatNumber(value) {
    let number = parseFloat(value);
    
    // Round to 3 decimal places
    number = Math.round(number * 1000) / 1000;
    
    // Convert to string with commas and 2 decimal places
    return number.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }

  // Get current transactions
  const indexOfLastTransaction = currentPage * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
  const currentTransactions = transactions.slice(indexOfFirstTransaction, indexOfLastTransaction);

  // Change page
  const nextPage = () => {
    if (currentPage < Math.ceil(transactions.length / transactionsPerPage)) {
      setCurrentPage(prev => prev + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prev => prev - 1);
    }
  };

  const toggleDropdown = () => {
    setIsDropdownVisible(prev => !prev);
  };  

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    // Listen for auth state changes
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user) {
            // Fetch user data from Firestore
            const userDoc = doc(db, "users", user.uid);
            const userSnapshot = await getDoc(userDoc);
            if (userSnapshot.exists()) {
                setUserData(userSnapshot.data());
                // Fetching transactions after setting userData
                fetchTransactions("All time", userSnapshot.data().ethereumAddress);
            } else {
                console.error("User document doesn't exist in Firestore.");
                setUserData({ error: "User data not found." });
            }
        } else {
            setUserData({ error: "No user authenticated." });
        }
    });

    // Clean up the listener on component unmount
    return () => unsubscribe();
}, []);

  return (
    <div className="desktop">
      <div className="header-navigation">
        <div className="container">
            {/* User Data Display */}

          <div className="content">
            <div className="logo">
              <img className="vector" alt="Vector" src="/img/vector.svg" />
              <Logomark className="logomark-instance" />
            </div>
            <div className="div-2">
              <div className="nav-item-base">
                <div className="div-3">
                  <BarChart01 className="bar-chart" color="#667085" />
                  <div className="text-5">Dashboard</div>
                </div>
              </div>
              <div className="content-wrapper">
              <a href="https://help.defispot.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                <div className="div-3">
                  <Dot className="design-component-instance-node" outline={false} size="md" />
                  <div className="text-5">Help Center</div>
                </div>
              </a>
              </div>
            </div>
          </div>
          <div className="div-2">
              <div className="dropdown" onClick={toggleDropdown}>
                <div className="avatar-2">
                  <div className="text-wrapper-2">
                    {(userData && userData.firstName) ? userData.firstName.charAt(0).toUpperCase() : "U"}
                  </div>
                  <div className="contrast-border-2" />
                </div>
                {isDropdownVisible && (
                  <div className="dropdown-menu">
                    <button onClick={handleLogout}>Log out</button>
                  </div>
                )}
              </div>
          </div>
        </div>
      </div>
      <div className="main">
        <div className="container-wrapper">
          <div className="page-header-wrapper">
            <div className="page-header">
              <div className="content-2">
                <div className="text-and-supporting">
                <div className="text-6">Welcome back, {userData && userData.firstName}</div>
                  <p className="supporting-text">This dashboard highlights metrics about your affiliate code "{(userData && userData.affiliateCode) ? userData.affiliateCode.toUpperCase() : 'UKNOWN'}".</p>
                </div>
                <div className="date-picker-dropdown">
                <div className="button-group">
                  <div className={`button-group-base ${selectedDateRange === "Today" ? "active" : ""}`} onClick={() => handleDateRangeChange("Today")}>
                      <div className="text-8">Today</div>
                  </div>
                  <div className={`div-wrapper ${selectedDateRange === "7 days" ? "active" : ""}`} onClick={() => handleDateRangeChange("7 days")}>
                      <div className="text-8">7 days</div>
                  </div>
                  <div className={`button-group-base-2 ${selectedDateRange === "30 days" ? "active" : ""}`} onClick={() => handleDateRangeChange("30 days")}>
                      <div className="text-8">30 days</div>
                  </div>
                  <div className={`button-group-base-3 ${selectedDateRange === "All time" ? "active" : ""}`} onClick={() => handleDateRangeChange("All time")}>
                      <div className="text-8">All time</div>
                  </div>
              </div>
              </div>
              <div className="div-3">
                <button className="button" onClick={() => fetchTransactions(selectedDateRange, userData.ethereumAddress)}>
                  <img className={`arrow-left ${isRefreshing ? 'spinning' : ''}`} alt="Refresh ccw" src="/img/refresh-ccw-02.svg" />
                  <div className="text-padding">
                    <div className="text-9">Refresh</div>
                  </div>
                </button>
                </div>
              </div>
              <img className="divider" alt="Divider" src="/img/divider.svg" />
            </div>
          </div>
        </div>
        <div className="container-wrapper">
          <div className="sections-wrapper">
            <div className="sections">
              <div className="section">
                <div className="content-3">
                  <div className="metric-item">
                    <div className="featured-icon">
                      <img className="img" alt="Bar chart up" src="/img/bar-chart-up.svg" />
                    </div>
                    <div className="heading-and-number">
                      <div className="heading">Trading Volume</div>
                      <div className="number-and-badge">
                      {isRefreshing ? <SkeletonLoader width="150px" height="43px" /> : <div className="number-2">$0.00</div>}
                      </div>
                    </div>
                  </div>
                  <div className="metric-item">
                    <div className="featured-icon">
                      <img className="img" alt="Deposit" src="/img/deposit.svg" />
                    </div>
                    <div className="heading-and-number">
                      <div className="heading">Captured Affiliate Fee</div>
                      <div className="number-and-badge">
                      {isRefreshing ? <SkeletonLoader width="150px" height="43px" /> : <div className="number-2">${formatNumber(totalAffiliateFee)}</div>}
                      </div>
                    </div>
                  </div>
                  <div className="metric-item">
                    <div className="featured-icon">
                      <img className="img" alt="Exchange swap" src="/img/exchange-swap.svg" />
                    </div>
                    <div className="heading-and-number">
                      <div className="heading">Transactions</div>
                      <div className="number-and-badge">
                      {isRefreshing ? <SkeletonLoader width="150px" height="43px" /> : <div className="number-2">{transactions.length}</div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table">
                <div className="card-header">
                    <div className="text-and-supporting-wrapper">
                        <div className="text-and-supporting-2">
                            <div className="text-and-badge">
                                <div className="text-10">Recent transactions</div>
                                <Badge
                                    className="badge-instance"
                                    color="brand"
                                    icon="false"
                                    size="sm"
                                    text={`${transactions.length} transactions`}
                                    textClassName="badge-2"
                                    type="pill-color"
                                />
                            </div>
                            <p className="p">An overview of the last transactions happened through your affiliate code.</p>
                        </div>
                    </div>
                    <img className="divider-2" alt="Divider" src="/img/divider.svg" />
                </div>
                <div className="content-4">
                    {/* Trade Column */}
                      <div className="column">
                        <div className="table-header-cell">
                          <div className="table-header-2">
                            <div className="text-11">Trade</div>
                          </div>
                        </div>

                        {isRefreshing ? (
                          Array.from({ length: transactionsPerPage }).map((_, index) => (
                            <div className="table-cell" key={index}>
                              <SkeletonLoader width="100%" height="20px" />
                            </div>
                          ))
                          ) : transactions.length === 0 ? (
                            <div className="no-transactions-message">
                              No transactions available.
                            </div>
                          ) : (
                          currentTransactions
                            .sort((a, b) => new Date(b.date) - new Date(a.date))
                            .map((transaction, index) => (
                              <div className="table-cell" key={index}>
                                <div className="symbol-circle">{transaction.from.symbol.charAt(0)}</div>
                                <div className="div-4">
                                  <div className="text-12">
                                    {formatNumber(transaction.amount)}
                                    <br />
                                    {transaction.from.symbol}
                                  </div>
                                </div>
                              </div>
                            ))
                        )}
                      </div>


                    {/* Arrow Column */}
                    <div className="column-2">
                      <div className="table-header-cell-2" />
                      {isRefreshing ? (
                        Array.from({ length: transactionsPerPage }).map((_, index) => (
                          <div className="arrow-left-wrapper" key={index}>
                            <SkeletonLoader width="50px" height="20px" />
                          </div>
                        ))
                      ) : currentTransactions.map((_, index) => (
                          <div className="arrow-left-wrapper" key={index}>
                            <ArrowLeft3 className="arrow-left" />
                          </div>
                      ))}
                    </div>

                    {/* Output Amount Column */}
                    <div className="column">
                      <div className="table-header-cell-3" />
                      {isRefreshing ? (
                        Array.from({ length: transactionsPerPage }).map((_, index) => (
                          <div className="table-cell" key={index}>
                            <SkeletonLoader width="100%" height="20px" />
                          </div>
                        ))
                      ) : currentTransactions.map((transaction, index) => (
                          <div className="table-cell" key={index}>
                            <div className="symbol-circle">{transaction.to.symbol.charAt(0)}</div>
                            <div className="div-4">
                              <div className="text-12">
                                {formatNumber(transaction.outputAmount)}
                                <br />
                                {transaction.to.symbol}
                              </div>
                            </div>
                          </div>
                      ))}
                    </div>

                    {/* Trade Value Column */}
                    <div className="column-3">
                      <div className="table-header-cell">
                        <TableHeader
                          arrow="false"
                          className="table-header-instance"
                          helpIcon={false}
                          stateProp="default"
                          text="Trade Value"
                        />
                      </div>
                      {isRefreshing ? (
                        Array.from({ length: transactionsPerPage }).map((_, index) => (
                          <div className="table-cell-2" key={index}>
                            <SkeletonLoader width="100%" height="20px" />
                          </div>
                        ))
                      ) : currentTransactions.map((transaction, index) => {
                          const unitUsdPrice = (transaction.rewards && transaction.rewards.length > 0) 
                                              ? transaction.rewards[0].unitUsdPrice 
                                              : 0;
                          return (
                            <div className="table-cell-2" key={index}>
                              <div className="text-and-supporting-3">
                                <div className="text-13">
                                  ${formatNumber(transaction.amount * unitUsdPrice)}
                                </div>
                              </div>
                            </div>
                          );
                      })}
                    </div>

                    {/* Your Affiliate Fee Column */}
                    <div className="column-3">
                      <div className="table-header-cell">
                        <TableHeader
                          arrow="false"
                          className="table-header-instance"
                          helpIcon={false}
                          stateProp="default"
                          text="Your Affiliate Fee"
                        />
                      </div>
                      {isRefreshing ? (
                        Array.from({ length: transactionsPerPage }).map((_, index) => (
                          <div className="table-cell-2" key={index}>
                            <SkeletonLoader width="100%" height="20px" />
                          </div>
                        ))
                      ) : currentTransactions.map((transaction, index) => {
                          const rewardValue = transaction.rewards && transaction.rewards.length > 0 
                                              ? (parseFloat(transaction.rewards[0].amountHumanReadable) * parseFloat(transaction.rewards[0].unitUsdPrice)) / 2 
                                              : 0;
                          return (
                            <div className="table-cell-2" key={index}>
                              <div className="text-and-supporting-3">
                                <div className="text-13">${formatNumber(rewardValue)}</div>
                              </div>
                            </div>
                          );
                      })}
                    </div>

                    {/* Date Column */}
                    <div className="column-3">
                      <div className="table-header-cell">
                        <TableHeader
                          arrow="false"
                          className="table-header-instance"
                          helpIcon={false}
                          stateProp="default"
                          text="Date"
                        />
                      </div>
                      {isRefreshing ? (
                        Array.from({ length: transactionsPerPage }).map((_, index) => (
                          <div className="table-cell-2" key={index}>
                            <SkeletonLoader width="100%" height="20px" />
                          </div>
                        ))
                      ) : currentTransactions.map((transaction, index) => (
                          <div className="table-cell-2" key={index}>
                            <div className="text-and-supporting-3">
                              <div className="text-13">{new Date(transaction.date).toLocaleDateString()}</div>
                            </div>
                          </div>
                      ))}
                    </div>

                {/* Three-Dot Icon Column */}
                <div className="column-3">
                  <div className="table-header-cell">
                    <TableHeader
                      arrow="false"
                      className="table-header-instance"
                      helpIcon={false}
                      stateProp="default"
                      text=""
                    />
                  </div>
                  {isRefreshing ? (
                    Array.from({ length: transactionsPerPage }).map((_, index) => (
                      <div className="table-cell-2" key={index}>
                        <SkeletonLoader width="50px" height="20px" />
                      </div>
                    ))
                  ) : currentTransactions.map((_, index) => (
                      <div className="table-cell-2" key={index}>
                        <img alt="More details" src="/img/table-cell.svg" />
                      </div>
                  ))}
                </div>
           </div>
        <div className="pagination">
            <div className="details">Page {currentPage} of {Math.ceil(transactions.length / transactionsPerPage)}</div>
            <div className="actions">
                <button className="buttons-button" onClick={prevPage}>
                    <div className="text-padding">
                        <div className="text-14">Previous</div>
                    </div>
                </button>
                <button className="buttons-button" onClick={nextPage}>
                    <div className="text-padding">
                        <div className="text-14">Next</div>
                    </div>
                </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
  );
};