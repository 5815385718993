import React from "react";
import ReactDOMClient from "react-dom/client";
import { AuthProvider } from "./authContext";  // Import the AuthProvider from authContext.js
import { App } from "./App";

const app = document.getElementById("app");
const root = ReactDOMClient.createRoot(app);

root.render(
  <AuthProvider>
    <App />
  </AuthProvider>
);