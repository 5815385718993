import React from "react";
import { Badge } from "../../components/Badge";
import { Dot } from "../../components/Dot";
import { TableHeader } from "../../components/TableHeader";
import { BarChart01 } from "../../icons/BarChart01";
import { Logomark } from "../../icons/Logomark";
import "./style.css";

export const NoData = () => {
  return (
    <div className="no-data">
      <div className="header-navigation-2">
        <div className="container-2">
          <div className="content-5">
            <div className="logo-2">
              <img className="vector-2" alt="Vector" src="/img/vector.svg" />
              <Logomark className="icon-instance-node" />
            </div>
            <div className="div-5">
              <div className="nav-item-base-2">
                <div className="div-6">
                  <BarChart01 className="bar-chart-01" color="#667085" />
                  <div className="text-15">Dashboard</div>
                </div>
              </div>
              <div className="nav-item-base-3">
                <div className="div-6">
                  <Dot className="dot-2" outline={false} size="md" />
                  <div className="text-15">Help Center</div>
                </div>
              </div>
            </div>
          </div>
          <div className="div-5">
            <div className="avatar-wrapper">
              <div className="avatar-3">
                <div className="text-wrapper-3">M</div>
                <div className="contrast-border-3" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-2">
        <div className="container-wrapper-2">
          <div className="container-3">
            <div className="page-header-2">
              <div className="content-6">
                <div className="text-and-supporting-4">
                  <div className="text-16">Welcome back, MacnBTC</div>
                  <p className="supporting-text-3">This dashboard highlights metrics about your affiliate program.</p>
                </div>
                <div className="button-group-wrapper">
                  <div className="button-group-2">
                    <div className="button-group-base-4">
                      <div className="text-17">Today</div>
                    </div>
                    <div className="button-group-base-5">
                      <div className="text-18">7 days</div>
                    </div>
                    <div className="button-group-base-6">
                      <div className="text-18">30 days</div>
                    </div>
                    <div className="button-group-base-7">
                      <div className="text-18">All time</div>
                    </div>
                  </div>
                </div>
                <div className="div-6">
                  <button className="button-2">
                    <img className="refresh-ccw" alt="Refresh ccw" src="/img/image.svg" />
                    <div className="text-padding-2">
                      <div className="text-19">Refresh</div>
                    </div>
                  </button>
                </div>
              </div>
              <img className="divider-3" alt="Divider" src="/img/divider.svg" />
            </div>
          </div>
        </div>
        <div className="container-wrapper-2">
          <div className="container-4">
            <div className="sections-2">
              <div className="section-2">
                <div className="content-7">
                  <div className="metric-item-2">
                    <div className="img-wrapper">
                      <img className="img-2" alt="Bar chart up" src="/img/bar-chart-up.svg" />
                    </div>
                    <div className="heading-and-number-2">
                      <div className="heading-2">Trading Volume</div>
                      <div className="number-wrapper">
                        <div className="number-3">$0</div>
                      </div>
                    </div>
                  </div>
                  <div className="metric-item-2">
                    <div className="img-wrapper">
                      <img className="img-2" alt="Deposit" src="/img/deposit.svg" />
                    </div>
                    <div className="heading-and-number-2">
                      <div className="heading-2">Captured Affiliate Fee</div>
                      <div className="number-wrapper">
                        <div className="number-4">$0</div>
                      </div>
                    </div>
                  </div>
                  <div className="metric-item-2">
                    <div className="img-wrapper">
                      <img className="img-2" alt="Exchange swap" src="/img/exchange-swap-2.svg" />
                    </div>
                    <div className="heading-and-number-2">
                      <div className="heading-2"> Transactions</div>
                      <div className="number-wrapper">
                        <div className="number-4">0</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-2">
                <div className="card-header-2">
                  <div className="content-8">
                    <div className="text-and-supporting-5">
                      <div className="text-and-badge-2">
                        <div className="text-20">Recent transactions</div>
                        <Badge
                          className="badge-3"
                          color="brand"
                          icon="false"
                          size="sm"
                          text="0 transactions"
                          textClassName="badge-4"
                          type="pill-color"
                        />
                      </div>
                      <p className="supporting-text-4">
                        An overview of the last transactions happened through your affiliate code.
                      </p>
                    </div>
                  </div>
                  <img className="divider-4" alt="Divider" src="/img/divider.svg" />
                </div>
                <div className="column-wrapper">
                  <div className="column-4">
                    <div className="table-header-wrapper">
                      <TableHeader
                        arrow="false"
                        className="table-header-3"
                        helpIcon={false}
                        stateProp="default"
                        text="Trade"
                      />
                    </div>
                    <div className="empty-state">
                      <div className="empty-state-2">
                        <div className="content-9">
                          <div className="group">
                            <div className="illustration">
                              <div className="overlap">
                                <div className="overlap-group">
                                  <div className="background-circle" />
                                  <div className="cloud">
                                    <div className="overlap-group-2">
                                      <div className="circle" />
                                      <div className="circle-2" />
                                      <div className="circle-3" />
                                    </div>
                                  </div>
                                  <div className="circle-4" />
                                  <div className="circle-5" />
                                </div>
                                <div className="circle-6" />
                              </div>
                              <div className="circle-7" />
                            </div>
                          </div>
                          <div className="text-and-supporting-6">
                            <div className="text-21">No transactions yet</div>
                            <p className="supporting-text-5">
                              There is no activity registered with your affiliate code yet.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pagination-2">
                  <div className="details-2">Page 1 of 1</div>
                  <div className="actions-2">
                    <button className="buttons-button-2">
                      <div className="text-padding-2">
                        <div className="text-22">Previous</div>
                      </div>
                    </button>
                    <button className="buttons-button-2">
                      <div className="text-padding-2">
                        <div className="text-22">Next</div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
