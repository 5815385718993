import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyC9j0flPJ93w4Dn4rsGv95BR4r1J7uosps",
  authDomain: "affiliate-defispot.firebaseapp.com",
  projectId: "affiliate-defispot",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
